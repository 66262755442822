import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
import router from "../router/index";
import axios from "axios";
// import { reject } from "core-js/fn/promise";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userProfile: {},
    isAuthenticated: false,
    registering: false
  },
  mutations: {
    setUserProfile(state, val, authState) {
      state.userProfile = val;
      if (val && Object.keys(val).length === 0 && val.constructor === Object)
        state.isAuthenticated = false;
      else state.isAuthenticated = true;
    },
    setRegistering(state, val) {
        state.registering = val;
    },
  },
  actions: {
    register({ dispatch, commit }, form) {
      // sign up user
      return new Promise((resolve, reject) => {
        fb.auth
        .createUserWithEmailAndPassword(form.email, form.password)
        .then((registration) => {
          const user = registration.user;
          user
            .updateProfile({
              displayName: form.name,
            })
            .then(() => {
              axios
                .post(
                  "https://us-central1-lavii-access-e7964.cloudfunctions.net/lavii_access/api/deliveryid/assign",
                  {
                    uid: user.uid,
                  }
                )
                .then((response) => {
                //   console.log(response.data);
                  let deliveryId = response.data.deliveryId;

                  fb.usersCollection
                    .doc(user.uid)
                    .set({
                      name: form.name,
                      phone: form.phone,
                      email: form.email,
                      locations: fb.firebase.firestore.FieldValue.arrayUnion(
                        form.locationId
                      ),
                      deliveryId: deliveryId,
                    })
                    .then(() => {
                      //   dispatch("fetchUserProfile", user);
                      commit("setUserProfile", {
                        name: form.name,
                        phone: form.phone,
                        email: form.email,
                        locations: [form.locationId],
                        deliveryId: deliveryId,
                      });
                      resolve(true)
                    }).catch((error)=>reject(error));
                }).catch((error)=>reject(error));;
            }).catch((error)=>reject(error));;
        }).catch((error)=>reject(error));;
      });
    },
    login({ dispatch }, form) {
      // sign in user
      fb.auth
        .signInWithEmailAndPassword(form.email, form.password)
        .then((signIn) => {
          const user = signIn.user;
          dispatch("fetchUserProfile", user);
        //   console.log("logged in");
          router.push("/dashboard");
        });

      // fetch user profile
    },
    async fetchUserProfile({ commit }, user) {
      //fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get();

      // set user profile
      commit("setUserProfile", userProfile.data());
    },
    async logout({ commit }) {
      await fb.auth.signOut();
      commit("setUserProfile", {});
      router.push("/login");
    },
  },
  modules: {},
});
