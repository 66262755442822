import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// var firebaseConfig = {
//     apiKey: "AIzaSyAnUJQLvGd21DK7nyK3Sv1GoxDUrlYAvr0",
//     authDomain: "lavii-crud-test.firebaseapp.com",
//     projectId: "lavii-crud-test",
//     storageBucket: "lavii-crud-test.appspot.com",
//     messagingSenderId: "395376833844",
//     appId: "1:395376833844:web:7bd41d5cf5ce88fb846161",
//     measurementId: "G-5JW8B4LSSE"
//   }

var firebaseConfig = {
  apiKey: "AIzaSyCwHTGE5-bMnf602kw1hmtphuXN69jD4gM",
  authDomain: "lavii-access-e7964.firebaseapp.com",
  projectId: "lavii-access-e7964",
  storageBucket: "lavii-access-e7964.appspot.com",
  messagingSenderId: "1021728802325",
  appId: "1:1021728802325:web:ffd13f16ceb9a87486701c",
  measurementId: "G-CNF2BBR3F7"
};

firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

// if (location.hostname === "localhost") {
//   console.log("using emulators");
//   db.useEmulator("localhost", 8081);
//   auth.useEmulator("http://localhost:9099");
// }

// collection ref
const usersCollection = db.collection('users')
// const gamesCollection = db.collection('games')

export {
    firebase,
    db,
    auth,
    storage,
    usersCollection
    // gamesCollection
}