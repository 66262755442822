<template>
    <v-row align="center" justify="center">
        <v-container class="mt-16">
            <center><v-img contain class="mb-10" src="https://lavii-inc.com/images/logoldpi-p-500.png" width="250" height="63"></v-img></center>
        </v-container>
       
            <v-card elevation="2">
                <v-card-text>
                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    >
                        
                        <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="Email"
                            outlined
                            required
                        >
                        </v-text-field>

                        <v-text-field
                            v-model="password"
                            :rules="passwordRules"
                            label="Password"
                            outlined
                            required
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            :append-icon="show ? 'mdi-eye' :  'mdi-eye-off'"
                        >
                        </v-text-field>

                        <center><v-btn
                            elevation="2"
                            color="primary"
                            @click="login"
                        >
                            Login
                        </v-btn>
                        <p class="pt-2 text-center">
                        Forgot Password? Click
                        <router-link :to="{ name: 'forgotPassword' }"> Here</router-link>
                        </p>
                        </center>
                    </v-form>
                    <!-- comment out for direct-links only -->
                    <!-- <center><p class="pt-2">Don't have an account? <router-link :to="{ name: 'Register' }">Register Now</router-link></p></center> -->
                </v-card-text>
            </v-card>
        
    </v-row>
</template>


<script>
export default {
    data() {
        return {
            show: false,
            valid: false,
            email: '',
            password: '',
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid'
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => v.length >= 8 || 'Password must be 8 characters or more'
            ]
        }
    },
    methods: {
        login() {
            const valid = this.$refs.form.validate()
            if(valid) {
                this.$store.dispatch('login', {             
                    email: this.email,
                    password: this.password
                })
            }
        }
    }
}
</script>