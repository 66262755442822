<template>
  <v-container class="mt-16">
    <v-row justify="center">
      <center>
        <v-img
          contain
          class="mb-10"
          src="https://lavii-inc.com/images/logoldpi-p-500.png"
          width="250"
          height="63"
        ></v-img>
        <h1 class="mb-4">Forgot Password</h1>
      </center>
    </v-row>
    <section>
      <v-row justify="center" class="pt-4">
        <v-card elevation="2" class="pt-4" width="70%">
          <v-card-text>
            <transition transition name="fade">
              <p :class="messageClasses" v-if="message && message.text">
                {{ message.text }}
              </p>
            </transition>
            <v-form @submit.prevent.stop="">
              <v-text-field
                v-model="email"
                placeholder="your-email@example.com"
                id="email"
                type="email"
                label="Enter Email"
                outlined
                required
              >
              </v-text-field>
              <center>
                <v-btn
                  elevation="2"
                  color="primary"
                  type="submit"
                  @click="sendEmail"
                >
                  <transition name="fade" mode="out-in">
                    <span v-if="!emailSending">{{buttonText}}</span>
                    <span v-else>Sending...</span>
                  </transition>
                </v-btn>
              </center>
            </v-form>
          </v-card-text>
        </v-card>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      email: "",
      message: null,
      emailSending: false,
      buttonText: 'send'
    };
  },
  computed: {
    messageClasses() {
      if (this.message && this.message.error) {
        return "pa-3 message errorMessage";
      } else {
        return "pa-3 message successMessage";
      }
    },
  },
  methods: {
    sendEmail() {
      if (!this.email) {
        this.message = {
          text: "Please type in a valid email address.",
          error: true,
        };
        return;
      }
      this.message = null;
      this.emailSending = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.emailSending = false;
          this.message = {
            text:
              `We have sent you an email with a link and instructions to reset your password. 
              If you have not received an email within 5 minutes, click button below to re-send.`,
            error: false,
          };
          this.buttonText = "Resend"
        })
        .catch((error) => {
          this.emailSending = false;
          this.message = {
            text: error.message,
            error: true,
          };
        });
    },
  },
};
</script>
<style>
.successMessage {
  background-color: #c8e6c9;
}
.errorMessage {
  background-color: #ffcdd2;
}
.message {
  font-size: medium;
  border-radius: 5px;
}
</style>
