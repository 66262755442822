<template>
  <v-container class="mt-16">
    <template v-if="whatToShow === 'registrationForm'">
      <v-row justify="center">
        <center>
          <v-img
            contain
            class="mb-10"
            src="https://lavii-inc.com/images/logoldpi-p-500.png"
            width="250"
            height="63"
          ></v-img>
          <h3 class="text-center" style="max-width:375px">
            Please fill out the form below to register and start accepting
            delivery orders.
          </h3>
        </center>
      </v-row>
      <v-row justify="center" class="pt-4">
        <v-card elevation="2">
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent.stop="register"
            >
              <v-text-field
                v-model="name"
                :rules="nameRules"
                label="Name"
                outlined
                required
              >
              </v-text-field>

              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                outlined
                required
              >
              </v-text-field>

              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                outlined
                required
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              >
              </v-text-field>

              <v-text-field
                v-model="phone"
                :rules="[phoneRules]"
                label="Phone"
                v-mask="{
                  mask: 'phone',
                  unmaskedVar: 'unmaskedPhoneNumber',
                }"
                outlined
                required
              >
              </v-text-field>

              <center>
                <v-btn
                  elevation="2"
                  color="primary"
                  type="button"
                  @click.stop.prevent="register"
                >
                  Register
                </v-btn>
              </center>
            </v-form>

            <p class="pt-2 text-center">
              Already have an account?
              <router-link :to="{ name: 'Login' }">Login Now</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-row>
    </template>
    <template v-if="whatToShow === 'loading'">
      <center>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </center>
    </template>
    <template v-if="whatToShow === 'invalidLink'">
      <v-row justify="center">
        <center>
          <v-img
            contain
            class="mb-10"
            src="https://lavii-inc.com/images/logoldpi-p-500.png"
            width="250"
            height="63"
          ></v-img>
          <h3 class="text-center" style="max-width:375px">
            Sorry, this is not a valid link
          </h3>
        </center>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { db, firebase } from "../../firebase";
import { mask } from "@titou10/v-mask";
export default {
  directives: { mask },
  methods: {},
  data() {
    return {
      whatToShow: "loading",
      show: false,
      valid: false,
      name: "",
      email: "",
      password: "",
      phone: "",
      unmaskedPhoneNumber: "",
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+/.test(v) || "Email must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be 8 characters or more",
      ],
      // phoneRules: [
      //   (v) => !!v || "Phone is required",
      //   (v) => v.length >= 10 || "Phone must be 8 characters or more",
      // ],
    };
  },
  props: {
    locationId: String,
  },
  async created() {
    if (this.$store.state.registering) {
      return;
    }
    this.validateLocationId(this.locationId);
  },
  mounted() {
    console.log("mounted");
  },
  methods: {
    async validateLocationId(locationId) {
      if (!locationId) return false;
      db.collection("locations")
        .where(firebase.firestore.FieldPath.documentId(), "==", locationId)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            console.log(`found the location id ${locationId}`);
            this.whatToShow = "registrationForm";
          } else {
            this.whatToShow === "invalidLink";
          }
        });
    },
    phoneRules(phone) {
      const phoneValid = new RegExp(/\+\d{11}/);
      let formattedPhone = this.formatPhone(phone)
      if (!phoneValid.test(formattedPhone)) {
        return false;
      }
      return true;
    },
    formatPhone(phone) {
      phone = phone.replace(/[^\d+]+/g, "");
      phone = phone.replace(/^00/, "+");
      if (phone.match(/^1/)) phone = "+" + phone;
      if (!phone.match(/^\+/)) phone = "+1" + phone;
      return phone;
    },
    register(e) {
      e.preventDefault();

      //hide the form
      this.whatToShow = "loading";
      this.$store.commit("setRegistering", true);

      const valid = this.$refs.form.validate();
      if (valid) {
        this.$store
          .dispatch("register", {
            name: this.name,
            email: this.email,
            password: this.password,
            phone: this.formatPhone(this.phone),
            locationId: this.locationId,
          })
          .then(() => {
            // console.log("then actually workes");
            this.$router.replace("/dashboard");
            this.$store.commit("setRegistering", false);
          })
          .catch((error) => {
            this.whatToShow = "registrationForm";
            this.$store.commit("setRegistering", false);
          });
      }
    },
  },
};
</script>
