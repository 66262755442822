import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Register from '../views/auth/Register'
import Login from '../views/auth/Login'
import Dashboard from '../views/Dashboard'
import firebase from 'firebase'
import { auth } from '../firebase'
import store from '../store/index';
import ForgotPassword from '../views/auth/ForgotPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/register/:locationId',
    name: 'Register',
    component: Register,
    props: (route) => ({locationId: route.params.locationId}) 
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
    beforeEnter(to, from, next) {
      const user = firebase.auth().currentUser;
      if (user) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from,  next) => {
  const requiresAuth = to.matched.some(x => x.meta.auth)

  if(requiresAuth && !auth.currentUser) {
    next('/login')
  }
  else {
    // This is necessary when navigating back to the root route ('/') page  
    // while the session is still valid.
    // if(['/dashboard'].includes(to.fullPath) && from.fullPath !== '/login')
    //     store.dispatch('fetchUserProfile', auth.currentUser);
    
    next()
  }
})





export default router
