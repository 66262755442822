<template>
    <div class="dashboard">
        <v-app-bar app color="white">
            <v-btn to="/" text>Lavii Smart Locker Access</v-btn>
            <v-spacer></v-spacer>
            <div v-if="!isAuthenticated">
                <v-btn :to="{ name: 'Login' }" text>Login</v-btn>
                <v-btn :to="{ name: 'Register' }" text>Register</v-btn>
            </div>
            <div v-else>
                <v-btn @click="logout" text>Logout</v-btn>
            </div>
        </v-app-bar>
        <h1 class="text-uppercase text-center">
            Welcome {{ userProfile.name }}!
        </h1>
        <v-divider class="my-4"></v-divider>
        <h3 class="text-center mb-2">Your delivery ID is:</h3>
        <center>
            <v-avatar color="blue" size="150">
                <span class="white--text headline">{{
                    userProfile.deliveryId
                }}</span>
            </v-avatar>
        </center>
        <v-divider class="my-5"></v-divider>
        <h3 class="mb-2">How to have food delivered to Lavii Locker:</h3>
        <ol class="mb-10">
            <li>Log into Delivery App (i.e.Doordash)</li>
            <li>Select Your Address</li>
            <li>Past suggested text into your delivery instructions</li>
        </ol>
        <v-row>
            <v-col cols="6" sm="6">
                <v-textarea
                    id="suggestedText"
                    outlined
                    label="Suggested Text"
                    :value="`Please deliver food to the Lavii Locker at my address. Use the code: ${userProfile.deliveryId}`"
                    ref="textToCopy"
                ></v-textarea>
            </v-col>
            <v-col cols="6" sm="6">
                <v-btn @click="copyText">Copy To Clipboard</v-btn>
            </v-col>
        </v-row>
        <h3 class="mb-2">How to pickup food from Lavii Locker:</h3>
        <p>
            When your food is delivered to the Lavii Locker,you will receive a
            text notification and email. Follow the steps below to retrieve
            food:
        </p>
        <ol>
            <li>Open text notification or email.</li>
            <li>Click link to retrieve QR code.</li>
            <li>Scan QR code.</li>
            <li>Retrieve food and close door behind you.</li>
        </ol>
    </div>
</template>


<script>
import { mapState } from "vuex";
export default {
    data() {
        return {};
    },
    computed: {
        ...mapState(["userProfile"]),
        ...mapState(["isAuthenticated"]),
    },
    methods: {
        copyText() {
            let text = document.querySelector("#suggestedText");
            text.select();

            try {
                var successful = document.execCommand("copy");
                var msg = successful ? "successful" : "unsuccessful";
            } catch (err) {}

            /* unselect the range */
            this.clearSelection();

            /* unfocus */
            if ("activeElement" in document) document.activeElement.blur();
        },
        clearSelection() {
            var sel;
            if ((sel = document.selection) && sel.empty) {
                sel.empty();
            } else {
                if (window.getSelection) {
                    window.getSelection().removeAllRanges();
                }
                var activeEl = document.activeElement;
                if (activeEl) {
                    var tagName = activeEl.nodeName.toLowerCase();
                    if (
                        tagName == "textarea" ||
                        (tagName == "input" && activeEl.type == "text")
                    ) {
                        // Collapse the selection to the end
                        activeEl.selectionStart = activeEl.selectionEnd;
                    }
                }
            }
        },
        logout() {
            this.$store.dispatch("logout");
        },
    },
};
</script> 
