<template>
	<v-app>
		<v-main>
			<v-container>
				<router-view />
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'App',

	data: () => ({
		//
	})
};
</script>
